import React from 'react';

import Container from 'components/Forms/Container';
import Row from 'components/Forms/Row';

import Query from 'services/Query';
import Form from 'services/Form';

export default class Edit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      user: this.props.user || {},
      regions: [],
      roles: [],
      organizations: [],
      distributors: [],
      errors: {},
    };

    this.save = this.save.bind(this);
    this.form = new Form(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.props.api.get('/users/' + this.props.id).then((response) => {
      response.data.user.originalAccessToken = response.data.user.access_token;
      this.userName = response.data.user.name || response.data.user.email;
      this.setState({...response.data, ready: true});
    });
  }

  save(event) {
    event.preventDefault();

    if(!this.state.user.password) {
      delete this.state.user.password;
    }

    this
      .props
      .api
      .post('/users/' + this.state.user.id, this.state.user)
      .then(Query.getRedirectCallback('/users'))
      .catch((error) => {
        this.setState(error.response.data);
      });
  }

  render() {
    return (
      <Container title={
        'Edit User ' + (this.userName ? ' "' + this.userName + '"' : '')
      }>
        <form onSubmit={this.save}>
          {this.form.fields.checkbox('user.activated', 'Activated')}
          {this.form.fields.input('user.name', 'Name')}
          {this.form.fields.input('user.company', 'Company')}
          {this.form.fields.input('user.email', 'Email')}

          {this.form.fields.input('user.password', 'Password', {
            type: 'password',
          })}

          {this.form.fields.checkbox(
            'user.can_receive_screeners',
            'Can receive screeners',
          )}

          {this.form.fields.checkbox(
            'user.transfers_enabled',
            'Is transfers enabled',
          )}

          {this.form.fields.checkbox(
            'user.access_token',
            'Enable API',
            {
              trueValue: this.state.user.originalAccessToken || true,
              value: this.state.user.access_token,
            },
          )}

          {
            this.state.user.access_token &&
              <Row
                title="Token"
                className="user-edit__field-access-token-value"
              >
                {this.state.user.access_token}
              </Row>
          }

          {this.form.fields.select(
            'user.region_cd',
            'Region',
            {values: this.state.regions},
          )}

          {this.props.currentUser.role === 'super_admin' && (
            <div>
              {this.form.fields.select(
                'user.role_cd',
                'Role',
                { values: this.state.roles },
              )}
              {this.state.user.role_cd === 0 && (
                this.form.fields.checkboxes(
                  'user.transfer_visibilities',
                  'Transfer Visibility',
                  { valuesObjects: this.state.transfer_modes },
                )
              )}
            </div>
          )}

          {this.form.fields.select(
            'user.primary_organization_id',
            'Primary Organization',
            {valuesObjects: this.state.organizations},
          )}

          {this.form.fields.checkboxes(
            'user.organization_ids',
            'Additional Organizations',
            {valuesObjects: this.state.organizations},
          )}

          {this.form.fields.checkbox(
            'user.is_notifications_active',
            'Receive notifications',
          )}

          <hr />

          <Row>
            <button type="submit" className="btn btn-primary">Save</button>
            &nbsp;
            <a
              href={Query.getRedirectUrl('/users')}
              className="btn btn-danger"
            >Cancel</a>

            <div className={
              (this.state.errors._ || []).length > 0 ? 'has-danger' : ''
            }>
              <div className="form-control-feedback">
                {(this.state.errors._ || []).join('; ')}
              </div>
            </div>
          </Row>
        </form>
      </Container>
    );
  }

}
