import React from 'react';
import Form from 'services/Form';
import Container from 'components/Forms/List';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      screens: [],
    };

    this.loading = false;
    this.form = new Form(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this
      .form
      .api
      .get('/screens', {organization_id: this.props.organizationId})
      .then((response) => {
        this.setState({...response.data});
      });
  }

  delete(screenId) {
    if(!(this.props.confirm || confirm)('Are you sure?')) {
      return ;
    }

    this
      .props
      .api
      .delete('/screens/' + screenId)
      .then(() => {
        this.setState({
          screens: this.state.screens.filter((screen) => {
            return screen.id != screenId;
          }),
        });
      });
  }

  render() {
    return (
      <Container>
        {this.props.currentUser.role === 'super_admin' && (
          <h1>
            Screens
            &nbsp;
            <a
              href={
                '/organizations/' + this.props.organizationId + '/screens/' +
                'create'
              }
              className="btn btn-primary pull-right"
            >
              Create
            </a>
          </h1>
        )}

        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Certificate</th>
              <th>Maker</th>
              <th>Model</th>
              <th>Serial</th>
              <th>Maker</th>
              <th>Model</th>
              <th>Serial</th>
              {this.props.currentUser.role === 'super_admin' && (
                <th>Actions</th>
              )}
            </tr>
          </thead>

          <tbody>
            {this.renderScreens(this.state.screens)}
          </tbody>
        </table>
      </Container>
    );
  }

  setEnabledState(screenId, index, isEnabled) {
    this
      .form
      .api
      .post('/screens/' + screenId, {enabled: isEnabled})
      .then(() => {
        this.form.set(`screens.${index}.enabled`, isEnabled);
      });
  }

  renderScreens(screens) {
    return screens.map((screen, index) => {
      return (
        <tr key={screen.id} className="screens__item">
          <td>{screen.name}</td>
          <td>
            {screen.certificate.url && (
              <a href={screen.certificate.url} target="_blank">
                {screen.certificate.url.split('/').pop()}
              </a>
            )}
          </td>
          <td>{screen.server_make}</td>
          <td>{screen.server_model}</td>
          <td>{screen.server_serial}</td>
          <td>{screen.projector_make}</td>
          <td>{screen.projector_model}</td>
          <td>{screen.projector_serial}</td>

          {this.props.currentUser.role === 'super_admin' && (
            <td className="screens__actions">
              <a
                href={
                  '/organizations/' + this.props.organizationId + '/screens/' +
                  screen.id
                }
                className="screens__targets btn btn-sm btn-primary"
                title="Edit"
              >
                <span className="fa fa-pencil"></span>
              </a>

              {screen.enabled && (
                <span>
                  &nbsp;

                  <button
                    className="screens__delete btn btn-sm btn-primary"
                    title="Deactivate screen"
                    onClick={() => this.setEnabledState(screen.id, index, false)}
                  >
                    <span className="fa fa-minus"></span>
                  </button>
                </span>
              )}

              {!screen.enabled && (
                <span>
                  &nbsp;

                  <button
                    className="screens__delete btn btn-sm btn-primary"
                    title="Activate screen"
                    onClick={() => this.setEnabledState(screen.id, index, true)}
                  >
                    <span className="fa fa-plus"></span>
                  </button>
                </span>
              )}

              {!screen.enabled && (
                <span>
                  &nbsp;

                  <button
                    className="screens__delete btn btn-sm btn-danger"
                    title="Delete screen"
                    onClick={() => this.delete(screen.id)}
                  >
                    <span className="fa fa-trash"></span>
                  </button>
                </span>
              )}
            </td>
          )}
        </tr>
      );
    });
  }

}
