import React from 'react';
import moment from 'moment/moment';

import Cache from 'services/Cache';
import Query from 'services/Query';
import I18n from 'services/I18n';

import ContentKindCheckbox from './Utilities/ContentKindCheckbox';

export default class Utilities {

  static getLowestId(playlists) {
    let lowestId =
      playlists
        .map((playlist) => {
          return playlist.id;
        })
        .sort()[0];

    return lowestId;
  }

  static isGroupHidden(visibleGroupsIds, playlists, lowestPlaylistId) {
    if(visibleGroupsIds.indexOf(lowestPlaylistId) === -1) {
      return true;
    }

    return false;
  }

  static preparePlaylists(playlists, type) {
    let lastDate = null;
    let groups = {};

    playlists
      .filter((playlist) => {
        if (playlist.sources.length > 0 && !playlist.sources.includes('filmservice')) {
          if (playlist[type]) {
            return true;
          }

          return false
        }

        let playlistType = playlist.theatrical_type;
        if(type == 'vod') {
          playlistType = playlist.homevideo_type;
        }

        if(playlistType === undefined) { return false }
        if(playlistType.hidden) {
          return false;
        }

        if(I18n.getLocale() != 'en') {
          let region = playlistType.region;
          if(!region || region[I18n.getLocale()] != '1') {
            return false;
          }
        }

        if(playlist[type]) {
          return true;
        }

        return false;
      })
      .forEach((playlist) => {
        let title = playlist.playlist_title;
        if(!title) {
          title = '[no title]';
        }

        if(!groups[title]) {
          groups[title] = [];
        }

        let localPlaylists = groups[title];

        localPlaylists.push(playlist);
        let isUpdateRequired = (
          !localPlaylists.updatedAt ||
            moment(playlist.mastering_date).isAfter(localPlaylists.updatedAt)
        );

        if(isUpdateRequired) {
          localPlaylists.updatedAt = playlist.mastering_date;
        }

        if(!title.toString().match(/Feature/)) {
          if(!lastDate || moment(playlist.mastering_date).isAfter(lastDate)) {
            lastDate = playlist.mastering_date;
          }
        }
      });

    Object.keys(groups).forEach((key) => {
      let isHidden = true;

      if((groups[key][0].playlist_title || '').toString().match(/Feature/)) {
        isHidden = false;
      }

      if(groups[key].updatedAt == lastDate) {
        isHidden = false;
      }

      Object.defineProperty(groups[key], 'isHidden', {
        enumerable: false,
        value: isHidden,
      });
    });

    return groups;
  }

  static getVisibleIds(groups) {
    let result =
      Object
        .keys(groups)
        .map((key) => {
          return groups[key];
        })
        .filter((groups) => {
          return !groups.isHidden;
        })
        .map((groups) => {
          return this.getLowestId(groups);
        });

    return result;
  }

  static getKinds() {
    let result = Query.get('kinds');
    if(result !== undefined) {
      return result;
    }

    let filmQuery = Cache.get('/films.query', {});
    if(filmQuery.kinds) {
      return filmQuery.kinds;
    }

    return '0,1,2,3,4,5,6,7,8,9,10';
  }

  static statusIconsTitles = [
    'Prepared',
    'In Progress',
    'Paused',
    'Delivered',
    'Failed',
    'Offline',
    'Cancelled',
  ];

  static getTransferIcon(transfer_status_cd, movietransit_status = null) {
    if (transfer_status_cd === null || transfer_status_cd === undefined) {
      return null;
    }

    return (
      <span>
        <i title={movietransit_status || this.statusIconsTitles[transfer_status_cd]} className={
          'icon icon-lg fa fa-fw ' + this.getTransferIconClass(
            transfer_status_cd,
          )
        } />
      </span>
    );
  }

  static statusIconsClasses = [
    'fa fa-circle-o asset-status--waiting', // prepared
    'fa fa-dot-circle-o asset-status--progress', // in_progress
    'fa fa-pause-circle asset-status--paused', // paused
    'fa fa-check-circle asset-status--done', // delivered
    'fa fa-check-circle grey-i', // failed
    'fa fa-times-circle grey-i asset-status--offline', // offline
    'fa fa-ban grey-i', // cancelled
  ];

  static getTransferIconClass(transfer_status_cd) {
    if(transfer_status_cd === null) {
      return 'fa fa-circle-o icon--empty';
    }

    let icon = this.statusIconsClasses[transfer_status_cd];
    if(icon) {
      return icon;
    }

    return 'fa fa-circle-o yellow-i';
  }

  static transferTypeIconClasses = {
    WebTransfer: 'fa fa-download',
    ApiTransfer: 'fa fa-cloud-download',
    FtpPull: 'fa fa-folder-open-o',
    SendDisk: 'fa fa-hdd-o',
  }

  static getTransferTypeIconClass(transfer_type) {
    return this.transferTypeIconClasses[transfer_type];
  }

  static setQueryKinds = (value) => {
    if(value.split(',').length === 11) {
      value = undefined;
    }

    Query.set('kinds', value);

    let filmsQuery = {...Cache.get('/films.query', {}), kinds: value};
    if(Query.get().distributor_id) {
      filmsQuery.distributor_id = Query.get().distributor_id;
    }

    Cache.set('/films.query', filmsQuery);
  }

  static contentKindCheckboxes() {
    return (
      <div className="filterbar-checkbox form-group">
        <label className="custom-control custom-checkbox">
          <ContentKindCheckbox
            value="0,1,3,4,5,6,7,8,9,10"
            actual={Utilities.getKinds()}
            onChange={Utilities.setQueryKinds}
          />

          <span className="custom-control-indicator">
            <i className="icon fa fa-check"></i>
          </span>

          <span className="custom-control-description">
            Trailer
          </span>
        </label>

        <label className="custom-control custom-checkbox">
          <ContentKindCheckbox
            value="2"
            actual={Utilities.getKinds()}
            onChange={Utilities.setQueryKinds}
          />

          <span className="custom-control-indicator">
            <i className="icon fa fa-check"></i>
          </span>

          <span className="custom-control-description">
            Features
          </span>
        </label>
      </div>
    );
  }

}
