import React from 'react';
import axios from 'axios';

import Errors from 'components/Forms/Errors';
import FormComponent from 'components/Forms/Form';
import Row from 'components/Forms/Row';

import DateTimePicker from 'services/DateTimePicker';
import ErrorsService from 'services/Errors';
import FormService from 'services/Form';
import I18n from 'services/I18n';
import Query from 'services/Query';

const debounce = require('lodash/debounce')

export default class Form extends FormComponent {

  static key = 'playlist';

  constructor(props) {
    super(props);

    this.state.displayFilenames = false;
    this.state.dcps = null;

    this.setDcp = this.setDcp.bind(this);
    this.loadDcps = debounce(this.loadDcps.bind(this), 500);
    this.guessData = this.guessData.bind(this);
    this.archive = this.archive.bind(this);
    this.unarchive = this.unarchive.bind(this);
    this.restoreDcp = this.restoreDcp.bind(this);
    this.reset = this.reset.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteDcp = this.deleteDcp.bind(this);
    this.setPlaylist = this.setPlaylist.bind(this);
    this.addTitle = this.addTitle.bind(this);
    this.confirm = this.confirm.bind(this);
    this.cancelConfirm = this.cancelConfirm.bind(this);
  }

  setDcp(event) {
    this.form.set('playlist.filename', event.target.dataset.dcp);
  }

  componentWillUnmount() {
    if(this.cancelDcpsLoading) {
      this.cancelDcpsLoading();
    }
  }

  loadDcps() {
    let query = {query: this.state.playlist.filename};

    if(this.cancelDcpsLoading) {
      this.cancelDcpsLoading();
    }

    this
      .props
      .api
      .get(
        '/playlists/dcps',
        query,
        {
          cancelToken: new axios.CancelToken((cancelExecutor) => {
            this.cancelDcpsLoading = cancelExecutor;
          }),
        },
      )
      .then((response) => {
        this.setState({dcps: response.data.dcps});
        this.cancelDcpsLoading = null;
      });
  }

  contentKindTitle(contentKind) {
    let value = this.state.content_kinds.find(([key]) => {
      return key == contentKind;
    });

    if(!value) {
      return null;
    }

    return value[1];
  }

  addTitle(event) {
    event.preventDefault();
    event.stopPropagation();

    let title = prompt('Enter new title', 'Trailer A');
    if(!title) {
      return ;
    }

    this.setState({
      titles: [...this.state.titles, title],
      playlist: {
        ...this.state.playlist,
        playlist_title: title,
      },
    });
  }

  guessData(event) {
    event.preventDefault();
    event.stopPropagation();

    let title = '';
    if(this.state.playlist.filename) {
      title = this.state.playlist.filename.replace(/^\s+|\s+$/g, '');
    }

    if(!title || title == this.state.original_filename) {
      if(this.state.playlist.cpl_title) {
        this.guessDataByTitle(this.state.playlist.cpl_title);
        return ;
      }

      if(!this.state.cplFile) {
        alert('upload cpl before guessing data');
        return ;
      }

      let reader = new FileReader();
      reader.onload = () => {
        let matches = reader.result.match(
          /<ContentTitleText>(.*?)<\/ContentTitleText>/
        );

        if(!matches) {
          alert('content title not found in cpl');
          return ;
        }

        this.guessDataByTitle(matches[1]);
      };

      reader.readAsText(this.state.cplFile);
      return ;
    }

    this.guessDataByTitle(title);
  }

  guessDataByTitle(title) {
    let playlist = {filename: title};

    this
      .props
      .api
      .get('/playlists/parse', {title: title})
      .then((response) => {
        this.form.set('playlist', {
          ...this.state.playlist,
          ...playlist,
          ...response.data.playlist,
        });
      })
      .catch(alert.bind(this, 'failed to guess data'));
  }

  archive(event) {
    event.preventDefault();
    event.stopPropagation();

    this
      .props
      .api
      .post('/playlists/' + this.state.playlist.id + '/archive')
      .then(() => {
        this.setState({archiving: true});
      })
      .catch(ErrorsService.alertHandler('archiving failed'));
  }

  resetConfirmation() {
    this.setState({
      confirmationRequired: false,
      confirmationAction: null,
      confirmation: null,
    });
  }

  unarchive(event) {
    event.preventDefault();
    event.stopPropagation();

    this
      .form
      .api
      .post('/playlists/' + this.state.playlist.id + '/unarchive')
      .then(() => {
        this.setState({unarchiving: true});
      })
      .catch(ErrorsService.alertHandler('unarchiving failed'));
  }

  deleteDcp(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({confirmationAction: 'deleteDcp'});

    let message = 'Are you sure you want to delete the DCP from dalco?';
    if(!confirm(message)) {
      return ;
    }

    this
      .form
      .api
      .post('/playlists/' + this.state.playlist.id + '/delete_dcp', {
        confirmation: this.state.confirmation,
      })
      .then(() => {
        this.resetConfirmation();
        alert('The DCP deletion has been scheduled');
      })
      .catch(ErrorsService.alertHandler('delete DCP failed'));
  }

  restoreDcp(event) {
    event.preventDefault();

    let message = `
Are you sure you want to restore the DCP from accelerators?

* Restore may take several days
* Do not start conversions until restore will be completed
* Ask developers if you need to know if DCP has been completed
`;

    if(!confirm(message)) {
      return ;
    }

    this
      .props
      .api
      .post('/playlists/' + this.state.playlist.id + '/restore_dcp')
      .then(() => {
        alert('Restore has started');
      })
      .catch(ErrorsService.alertHandler('restore start failed'));
  }

  reset(event) {
    event.preventDefault();
    this.setState({confirmationAction: 'reset'});

    let message = `
Are you sure you want to reset the playlist?

* Transfer hash and transfer url will be reset
* The DCP will be deleted from dalco
* You will be able to ingest the DCP with same filename and UUID
* All playlists transfers will be restarted
* DCP delivery email will not be sent again
* Ask developer to send DCP delivery email if you need it
`;

    if(!confirm(message)) {
      return ;
    }

    this
      .form
      .api
      .post('/playlists/' + this.state.playlist.id + '/reset', {
        confirmation: this.state.confirmation,
      })
      .then(() => {
        this.props.load();
        this.resetConfirmation();
        alert('Playlist has been reset');
      })
      .catch(ErrorsService.alertHandler('reset failed'));
  }

  delete(event) {
    event.preventDefault();
    this.setState({confirmationAction: 'delete'});

    let message = `
Are you sure you want to delete the playlist?

* This playlist will be deleted from application
* The DCP will not be deleted from dalco
* All transfers of this playlist will be deleted
`;

    if(!confirm(message)) {
      return ;
    }

    this
      .form
      .api
      .delete('/playlists/' + this.state.playlist.id, {
        confirmation: this.state.confirmation,
      })
      .then(() => {
        alert('Playlist has been deleted');
        this.resetConfirmation();
        Query.redirect(`/films/${this.state.playlist.film_id}/edit`);
      })
      .catch(ErrorsService.alertHandler('delete failed'));
  }

  confirm(event) {
    event.preventDefault();
    this[this.state.confirmationAction](event);
  }

  cancelConfirm(event) {
    event.preventDefault();
    this.resetConfirmation();
  }

  render() {
    return (
      <form onSubmit={this.save}>
        {this.renderBody()}

        <hr />

        <div className="form-group row">
          <div className="col-sm-offset-4 col-sm-8">
            <table className="table">
              <tbody>
                <tr>
                  <th>On Dalco</th>
                  <td>
                    <span className="histories">
                      <span className="asset-status-item transfer-icon">
                        <i className={
                          'fa fa-check-circle icon icon-lg ' + (
                            this.state.playlist.on_dalco ?
                              'asset-status--done' :
                              'grey-i'
                          )
                        } />
                      </span>
                    </span>
                  </td>

                  <td>
                    {this.state.archiving && 'Archiving...'}
                    {this.state.unarchiving && 'Unarchiving...'}

                    {!this.state.archiving && !this.state.unarchiving && (
                      <div>
                        {
                          this.state.playlist.on_dalco &&
                            !this.state.playlist.on_glacier && (
                              <span style={{marginRight: '30px'}}>
                                <a href="#" onClick={this.archive}>
                                  Archive
                                </a>
                              </span>
                            )
                        }

                        {
                          !this.state.playlist.on_dalco &&
                            this.state.playlist.on_glacier && (
                              <span style={{marginRight: '30px'}}>
                                <a href="#" onClick={this.unarchive}>
                                  Unarchive
                                </a>
                              </span>
                            )
                        }

                        {this.state.playlist.on_dalco && (
                          <span style={{marginRight: '30px'}}>
                            <a href="#" onClick={this.deleteDcp}>Delete</a>
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                </tr>

                <tr>
                  <th>On Disk</th>
                  <td>
                    <span className="histories">
                      <span className="asset-status-item transfer-icon">
                        <i className={
                          'fa fa-check-circle icon icon-lg ' + (
                            this.state.on_archive_disk ?
                              'asset-status--done' :
                              'grey-i'
                          )
                        } />
                      </span>
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>On Glacier</th>
                  <td>
                    <span className="histories">
                      <span className="asset-status-item transfer-icon">
                        <i className={
                          'fa fa-check-circle icon icon-lg ' + (
                            this.state.playlist.on_glacier ?
                              'asset-status--done' :
                              'grey-i'
                          )
                        } />
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {
          this.state.confirmationRequired &&
            this.state.confirmationAction !== null && (
              <div>
                <div className="row">
                  <div className="col-sm-6">
                    {
                      this.form.fields.input(
                        'confirmation',
                        null,
                        {
                          input: {
                            placeholder: 'Confirmation Code',
                          },
                          comment: (
                            <p>
                              Check delete@diagonal.com for confirmation code
                            </p>
                          ),
                        },
                      )
                    }
                  </div>
                  <div className="col-sm-6">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={this.confirm}
                    >Confirm Action</button>

                    &nbsp;

                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.cancelConfirm}
                    >Cancel</button>
                  </div>
                </div>
              </div>
            )
        }

        {
          !this.state.confirmationRequired && (
            <Row>
              <Errors errors={this.state.errors.fatal} />
              <button type="submit" className="btn btn-primary">Save</button>
              &nbsp;

              <a
                href={'/films/' + this.state.playlist.film_id + '/edit'}
                className="btn btn-default"
              >Cancel</a>

              <div className="pull-right">
                {this.state.playlist.id &&
                  !this.state.playlist.on_dalco &&
                  this.state.playlist.torrent_hash && (
                  <button
                    onClick={this.restoreDcp}
                    title="transfer DCP from accelerators to dalco"
                    className="btn btn-success"
                  >Restore</button>
                )}
              </div>
            </Row>
          )
        }
      </form>
    );
  }

  setPlaylist(playlist, errors) {
    let newState = {
      playlist: {...this.state.playlist, ...playlist},
    };

    if(JSON.stringify(this.state.errors) != JSON.stringify(errors || {})) {
      newState.errors = {...this.state.errors, ...errors};
    }

    this.setState(newState);
  }

  renderBody() {
    let playlist = this.state.playlist;

    return (
      <div>
        {this.renderBasicFields()}
        {this.renderDcpInput()}
        {this.renderFileFields()}

        <VersionFields
          errors={this.state.errors}
          loading={this.state.loading}
          setPlaylist={this.setPlaylist}
          cinema={playlist.cinema}
          webfoyer={playlist.webfoyer}
          vod={playlist.vod}
          intermission={playlist.intermission}
          end_credit={playlist.end_credit}
          versions={this.state.versions}
          content_kind={playlist.content_kind}
          content_kind_cd={playlist.content_kind_cd}
        />

        <AudioFields
          errors={this.state.errors}
          loading={this.state.loading}
          setPlaylist={this.setPlaylist}
          playlists={this.state.playlists}
          dubbed_audio_id={playlist.dubbed_audio_id}
          original_audio_id={playlist.original_audio_id}
          visually_impaired_audio_id={playlist.visually_impaired_audio_id}
        />

        <LanguageFields
          errors={this.state.errors}
          loading={this.state.loading}
          setPlaylist={this.setPlaylist}
          films={this.state.films}
          playlists={this.state.playlists}
          languages={this.state.languages}
          subtitle_languages={this.state.subtitle_languages}
          conversion_targets={playlist.conversion_targets}
          distribution={playlist.distribution}
          with_film_id={playlist.with_film_id}
          replaces_playlist_id={playlist.replaces_playlist_id}
          spoken_language={playlist.spoken_language}
          subtitle_language1={playlist.subtitle_language1}
          subtitle_language2={playlist.subtitle_language2}
          subtitle_language3={playlist.subtitle_language3}
        />

        <OtherFields
          errors={this.state.errors}
          loading={this.state.loading}
          setPlaylist={this.setPlaylist}
          aspect_ratios={this.state.aspect_ratios}
          audio_types={this.state.audio_types}
          dimensions={this.state.dimensions}
          resolutions={this.state.resolutions}
          standards={this.state.standards}
          archive_disks={this.state.archive_disks}
          aspect_ratio={playlist.aspect_ratio}
          audio_type={playlist.audio_type}
          dimension={playlist.dimension}
          fps={playlist.fps}
          duration={playlist.duration}
          mastering_date={playlist.mastering_date}
          resolution={playlist.resolution}
          standard={playlist.standard}
          archive_disk_ids={playlist.archive_disk_ids}
        />

        {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && (
          <div>
            {this.form.fields.value(
              'playlist.torrent_hash',
              'Transfer Hash',
              {empty: (<span>&mdash;</span>)},
            )}

            {this.form.fields.value(
              'playlist.torrent_filename',
              'Transfer Uuid Name',
              {empty: (<span>&mdash;</span>)},
            )}

            <Row title="Transfer Link">
              <div className="form-control-static">
                {!this.state.playlist.torrent_url && (<span>&mdash;</span>)}

                {this.state.playlist.torrent_url && (
                  <a href={this.state.playlist.torrent_url}>
                    {this.state.playlist.torrent_url}
                  </a>
                )}
              </div>
            </Row>
          </div>
        )}
      </div>
    );
  }

  renderBasicFields() {
    let playlist = this.state.playlist;

    return (
      <div>
        <Row
          title="Playlist Title"
          className="playlist-edit__field-playlist_title"
        >
          {this.form.fields.select('playlist.playlist_title', null, {
            valuesArray: this.state.titles,
            blank: false,
            noErrors: true,
          })}

          &nbsp;

          <a href="#" onClick={this.addTitle}>New Title</a>
        </Row>

        {playlist.id && (
          <div>
            <span className={(!playlist.on_dalco && playlist.on_glacier && 'italic')}>
              {this.form.fields.value('playlist.title', 'Content Title')}
            </span>

            {this.form.fields.value('playlist.uuid', 'UUID')}
            {this.form.fields.input('playlist.asset_uuid', 'ASSETMAP UUID')}

            {this.form.fields.input(
              'playlist.title_version_description',
              'Title Version Description',
            )}
          </div>
        )}

        {
          playlist.cpl &&
            playlist.cpl.url &&
            playlist.content_kind_cd !== undefined &&
            this.form.fields.value(
              'playlist.content_kind_cd',
              'Content Kind',
              {
                value: this.contentKindTitle(playlist.content_kind_cd),
                blank: false,
              },
            )
        }

        {
          (
            !playlist.cpl ||
              !playlist.cpl.url ||
              playlist.content_kind_cd === undefined
          ) &&
            this.form.fields.select(
              'playlist.content_kind_cd',
              'Content Kind',
              {
                values:
                  this.state.content_kinds &&
                    this.state.content_kinds.map((contentKind) => {
                      return [
                        contentKind[0],
                        contentKind[1].charAt(0).toUpperCase() +
                          contentKind[1].slice(1),
                      ];
                    }),
                blank: false,
              },
            )
        }
      </div>
    );
  }

  renderDcpInput() {
    return (
      <Row title="DCP" className="playlist-edit__field-filename">
        <div
          className="dropdown open"
        >
          {this.form.fields.input('playlist.filename', null, {
            input: {
              disabled: this.state.is_filename_disabled,
              onFocus: () => {
                this.loadDcps();
                this.setState({displayFilenames: true, dcps: null});
              },
              onBlur: () => {
                window.setTimeout(
                  () => {
                    this.setState({displayFilenames: false});
                  },
                  250,
                )
              },
              onChange: (event) => {
                this.loadDcps();
                this.form.set('playlist.filename', event.target.value);
              },
            },
          })}

          {this.state.displayFilenames && (
            <div className="dropdown-menu playlists-form__dcps">
              {this.state.dcps === null && (
                <div className="playlists-form__dcp-searching">
                  Searching...
                </div>
              )}

              {this.state.dcps !== null && this.state.dcps.length === 0 && (
                <div className="playlists-form__dcp-searching">
                  No DCP found
                </div>
              )}

              {this.state.dcps && this.state.dcps.map((dcp) => {
                return (
                  <a
                    href="#"
                    key={dcp}
                    className="playlists-form__dcp"
                    onClick={this.setDcp}
                    data-dcp={dcp}
                  >{dcp}</a>
                );
              })}
            </div>
          )}
        </div>
      </Row>
    );
  }

  renderFileFields() {
    return (
      <div>
        {this.form.fields.file('playlist.subtitles', 'Subtitles')}

        {this.form.fields.select('playlist.package_type', 'Package Type', {
          valuesArray: this.state.package_types,
          blank: false,
        })}

        {
          this.state.playlists &&
            this.state.playlist.package_type == 'VF' &&
            this.form.fields.select(
              'playlist.version_file_id',
              'Original Version Required',
              {
                values:
                  this
                    .state
                    .playlists
                    .filter((playlist) => {
                      return (
                        playlist.package_type == 'OV' &&
                          playlist.content_kind_cd ==
                            this.state.playlist.content_kind_cd
                      );
                    })
                    .map((playlist) => {
                      return [
                        playlist.id,
                        playlist.title || playlist.filename,
                      ];
                    }),
                blank: true,
              },
            )
        }

        <Row title="CPL" className="playlist-edit__field-cpl">
          {this.form.fields.file('playlist.cpl', null, {
            onFile: (file) => {
              this.setState({cplFile: file});
            },
          })}

          <a
            href="#"
            className="playlist-edit__guess-data"
            onClick={this.guessData}
          >guess data</a>
        </Row>

        {this.form.fields.file('playlist.dkdm', 'DKDM')}

        <Row title="Encrypted" className="playlist-edit__field-encrypted">
          {this.state.playlist.encrypted ? 'Yes' : 'No'}
        </Row>

      </div>
    );
  }

}

class FieldsHelper extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = props;
    this.form = new FormService(this, {
      onSetState: (state) => {
        this.props.setPlaylist(state.playlist, state.errors);
      },
    });
  }

  componentWillReceiveProps(props) {
    this.setState(props);
  }

}

class VersionFields extends FieldsHelper {

  formatTimecode(time) {
    if(!time) {
      return time;
    }

    return time.replace(/\D/g, '').match(/\d{1,2}/g).join(':');
  }

  finalizeTimecode(time) {
    if(!time) {
      return time;
    }

    let result =
      time
        .replace(/\D/g, '')
        .match(/\d{1,2}/g)
        .map((value) => {
          if(value.length == 1) {
            value = '0' + value;
          }

          return value;
        })
        .join(':');

    let zero = '00:00:00:00';
    if(result.length < zero.length) {
      result = zero.substr(0, zero.length - result.length) + result;
    }

    return result;
  }

  render() {
    return (
      <div>
        {this.form.fields.checkbox('playlist.cinema', 'Theatrical', {
          value: this.props.cinema,
        })}

        {this.form.fields.checkbox('playlist.webfoyer', 'Foyer', {
          value: this.props.webfoyer,
        })}

        {this.form.fields.checkbox('playlist.vod', 'Homevideo', {
          value: this.props.vod,
        })}

        {
          [1, 2].includes(this.props.content_kind_cd) &&
            this.form.fields.input('playlist.intermission', 'Intermission', {
              value: this.props.intermission,
              onChange: this.form.setter(
                'playlist.intermission',
                this.formatTimecode,
              ),
              input: {
                size: 11,
                maxLength: 11,
                onBlur: this.form.callback('intermission.blur', () => {
                  if(!this.state) {
                    return ;
                  }

                  this.form.set(
                    'playlist.intermission',
                    this.finalizeTimecode(this.state.playlist.intermission),
                  );
                }),
              },
            })
        }

        {
          [1, 2].includes(this.props.content_kind_cd) &&
            this.form.fields.input('playlist.end_credit', 'End credit', {
              value: this.props.end_credit,
              onChange: this.form.setter(
                'playlist.end_credit',
                this.formatTimecode,
              ),
              input: {
                size: 11,
                maxLength: 11,
                onBlur: this.form.callback('end_credit.blur', () => {
                  if(!this.state) {
                    return ;
                  }

                  this.form.set(
                    'playlist.end_credit',
                    this.finalizeTimecode(this.state.playlist.end_credit),
                  );
                }),
              },
            })
        }
      </div>
    );
  }

}

class AudioFields extends FieldsHelper {

  valueCallback(playlist) {
    return [playlist.id, playlist.title || playlist.filename];
  }

  render() {
    return (
      <div>
        {this.form.fields.select(
          'playlist.dubbed_audio_id',
          'Dubbed audio',
          {
            blank: 'Not set',
            value: this.props.dubbed_audio_id,
            values: this.props.playlists,
            valueCallback: this.valueCallback,
          },
        )}

        {this.form.fields.select(
          'playlist.original_audio_id',
          'Original audio',
          {
            blank: 'Not set',
            value: this.props.original_audio_id,
            values: this.props.playlists,
            valueCallback: this.valueCallback,
          },
        )}

        {this.form.fields.select(
          'playlist.visually_impaired_audio_id',
          'Visually impaired audio',
          {
            blank: 'Not set',
            value: this.props.visually_impaired_audio_id,
            values: this.props.playlists,
            valueCallback: this.valueCallback,
          },
        )}
      </div>
    );
  }

}

class LanguageFields extends FieldsHelper {

  render() {
    return (
      <div>
        {this.form.fields.textarea(
          'playlist.conversion_targets',
          'Additional conversion parameters',
          {value: this.props.conversion_targets},
        )}

        {this.form.fields.checkbox(
          'playlist.distribution',
          'Distribution per default',
          {value: this.props.distribution}
        )}

        {this.form.fields.select(
          'playlist.with_film_id',
          'Play with film',
          {
            blank: 'Not set',
            value: this.props.with_film_id,
            values: this.props.films,
            valueCallback: (film) => {
              return [film.film_id, film.title];
            },
          },
        )}

        {this.form.fields.select(
          'playlist.replaces_playlist_id',
          'Replaces playlist',
          {
            blank: 'Not set',
            value: this.props.replaces_playlist_id,
            values: this.props.playlists,
            valueCallback: (playlist) => {
              return [playlist.id, playlist.title || playlist.filename];
            },
          },
        )}

        {this.form.fields.select(
          'playlist.spoken_language',
          'Spoken language',
          {
            blank: 'Not set',
            value: this.props.spoken_language,
            valuesArray: this.props.languages,
          },
        )}

        {this.form.fields.select(
          'playlist.subtitle_language1',
          'Language subtitles 1',
          {
            blank: false,
            value: this.props.subtitle_language1,
            valuesArray: this.props.subtitle_languages,
          },
        )}

        {this.form.fields.select(
          'playlist.subtitle_language2',
          'Language subtitles 2',
          {
            blank: false,
            value: this.props.subtitle_language2,
            valuesArray: this.props.subtitle_languages,
          },
        )}

        {this.form.fields.select(
          'playlist.subtitle_language3',
          'Language subtitles 3',
          {
            blank: false,
            value: this.props.subtitle_language3,
            valuesArray: this.props.subtitle_languages,
          },
        )}
      </div>
    );
  }

}

class OtherFields extends FieldsHelper {

  render() {
    return (
      <div>
        {this.form.fields.select('playlist.dimension', 'Dimension', {
          value: this.props.dimension,
          valuesArray: this.props.dimensions,
          blank: false,
        })}

        {this.form.fields.select('playlist.resolution', 'Resolution', {
          value: this.props.resolution,
          valuesArray: this.props.resolutions,
          blank: false,
        })}

        {this.form.fields.select('playlist.aspect_ratio', 'Aspect ratio', {
          value: this.props.aspect_ratio,
          valuesArray: this.props.aspect_ratios,
          blank: false,
        })}

        {this.form.fields.select('playlist.audio_type', 'Audio', {
          value: this.props.audio_type,
          valuesArray: this.props.audio_types,
          blank: false,
        })}

        {this.form.fields.select('playlist.fps', 'FPS', {
          value: this.props.fps,
          valuesArray: [24, 25, 48],
          blank: false,
        })}

        {this.form.fields.value('playlist.duration', 'Duration', {
          value: this.props.duration,
        })}

        {this.form.fields.input(
          'playlist.mastering_date',
          'Publication Date',
          {
            value: I18n.date(this.props.mastering_date),
            input: {
              ref: DateTimePicker.binder(
                this.form.setter('playlist.mastering_date'),
                {format: 'DD.MM.YYYY'},
              ),
            },
          },
        )}

        {this.form.fields.select('playlist.standard', 'Standard', {
          value: this.props.standard,
          valuesArray: this.props.standards,
          blank: false,
        })}

        {this.form.fields.checkboxes(
          'playlist.archive_disk_ids',
          'Archive Disks',
          {
            value: this.props.archive_disk_ids,
            valuesObjects: this.props.archive_disks,
          },
        )}
      </div>
    );
  }

}
