import React from 'react';

import App from 'components/App';

import Query from 'services/Query';
import Form from 'services/Form';
import Script from 'services/Script';

require('js-marker-clusterer/src/markerclusterer.js');

export default class Map extends React.Component {

  constructor(props) {
    super(props)

    this.state = {};
    this.form = new Form(this);
  }

  componentDidMount() {
    if(window.google === undefined) {
      Script.load(
        '//maps.google.com/maps/api/js?v=3.13&sensor=false&' +
          'libraries=geometry&key=AIzaSyDD9MoQWnYMk4Wl0DuzrAUT28t5eZwoTP0',
      );
    }

    if(window._ === undefined) {
      Script.load(
        '//cdnjs.cloudflare.com/ajax/libs/underscore.js/1.8.3/' +
          'underscore-min.js',
      );
    }

    if(window.Gmaps === undefined) {
      Script.load('/static/js/gmaps.js').then(() => {
        this.forceUpdate();
      });
    }

    App.setTitle('Map');

    this.form.api.receive('/organizations/map', {
      transfer_mode_ftr: Query.get('transfer_mode_ftr'),
    });
  }

  createMap() {
    if(window.Gmaps === undefined) {
      return ;
    }

    if(window.google === undefined) {
      return ;
    }

    if(window._ === undefined) {
      return ;
    }

    let google = window.google;
    let gmap = window.Gmaps.build('Google');

    gmap.buildMap(
      {
        provider: {
          center: new google.maps.LatLng(47.386096, 8.542851),
          zoom: 10,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          panControl: true,
          panControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
        },
        internal: {id: 'map'},
      },
      () => {
        gmap.bounds.extendWith(
          gmap.addMarkers(
            this.state.organizations.map((organization) => {
              return {
                lat: organization.lat,
                lng: organization.lng,
                marker_title: organization.name,
                infowindow:
                  `<a href="/organizations/${organization.id}">` +
                    `${organization.name}</a> `,
                picture: {
                  url:
                    '/static/images/' + (
                      ['disk'].includes(
                        organization.transfer_mode_ftr
                      ) ?
                        'organization-without-feature-transfer.png' :
                        'organization-with-feature-transfer.png'
                    ),
                  width: 32,
                  height: 32,
                },
              };
            }),
          ),
        );

        gmap.fitMapToBounds();
      },
    );

    google.maps.event.addDomListener(window, 'resize', function() {
      let map = gmap.getMap();
      google.maps.event.trigger(map, 'resize');
      map.setCenter(map.getCenter());
    });
  }

  render() {
    if(typeof google === 'undefined') {
      return null;
    }

    if(!this.state.organizations) {
      return null;
    }

    return (
      <div id="map" ref={this.createMap.bind(this)}></div>
    );
  }

}
