import React from 'react';

import Checkbox from 'components/Forms/Checkbox';
import Checkboxes from 'components/Forms/Checkboxes';
import Dropdown from 'components/Forms/Dropdown';
import Input from 'components/Forms/Input';
import Row from 'components/Forms/Row';
import Select from 'components/Forms/Select';
import Textarea from 'components/Forms/Textarea';
import Value from 'components/Forms/Value';
import File from 'components/Forms/File';

export default class FormFields {

  constructor(form) {
    this.form = form;
  }

  row(key, title, Component, props) {
    if(!props) {
      props = {};
    }

    let className = null;
    if(key) {
      let keys = this.form.keyToArray(key);

      className =
        keys[0] + '__field-' + keys.slice(1).join('').replace(/\W/g, '-');
    }

    let component = Component;

    if(typeof Component === 'function') {
      let resultProps = {...this.props(key), ...props};
      component = (
        <Component {...resultProps} />
      );
    }

    if(title === undefined || title === null) {
      return component;
    }

    return (
      <Row title={title} className={className}>
        {component}
        {(props || {}).comment}
      </Row>
    );
  }

  value(key, title, props) {
    return this.row(key, title, Value, props);
  }

  input(key, title, props) {
    return this.row(key, title, Input, props);
  }

  textarea(key, title, props) {
    return this.row(key, title, Textarea, props);
  }

  checkbox(key, title, props) {
    return this.row(key, title, Checkbox, props);
  }

  checkboxes(key, title, props) {
    return this.row(key, title, Checkboxes, props);
  }

  select(key, title, props) {
    const { default: defaultValue, ...restProps } = props || {};

    let currentValue = this.form.get(key);

    if (currentValue === undefined && defaultValue !== undefined) {
      currentValue = defaultValue;
      this.form.set(key, defaultValue);
    }
    return this.row(key, title, Select, props);
  }

  dropdown(key, title, props) {
    return this.row(key, title, Dropdown, props);
  }

  file(key, title, props) {
    let keys = this.form.keyToArray(key);
    let prefix = keys.slice(0, keys.length - 1);
    let postfix = keys.slice(-1)[0];

    let value = this.form.get(key);

    let resultProps = {
      ...this.props(key),
      onChange: this.form.callback(key, (value, fileName) => {
        this.form.set(prefix, {
          ...this.form.get(prefix),
          [postfix + '_filename']: fileName,
          ['remove_' + postfix]: false,
          [postfix]: value,
        });
      }),
      ...props,
      value: value,
    };

    return this.row(key, title, (
      <File {...resultProps}>
        {resultProps.file}

        {value && value.url && (
          <span>
            {value.url.match(/\.(jpg|jpeg|png)$/) && (
              <span>
                <img style={{maxWidth: '200px'}} src={value.url} />
                <br />
              </span>
            )}

            <a target="_blank" href={value.url}>
              {value.url.split('/').slice(-1)[0]}
            </a>
          </span>
        )}

        &nbsp;

        <sup>
          <a href="#" className="danger" onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            this.form.extend(prefix, {
              [postfix]: null,
              ['remove_' + postfix]: true,
            });
          }}>x</a>
        </sup>
      </File>
    ));
  }

  props(key) {
    if(!key) {
      return {};
    }

    let keys = this.form.keyToArray(key);

    let errorKey = keys;
    if(errorKey.length > 1) {
      errorKey = errorKey.slice(1);
    }

    return {
      value: this.form.get(keys),
      onChange: this.form.setter(keys),
      disabled:
        this.form.component.state.loading ||
          this.form.component.props.loading,
      errors: this.form.get(['errors'].concat(errorKey)),
    };
  }

}
