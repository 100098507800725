import React from 'react';

import Row from 'components/Forms/Row';

import FormComponent from 'components/Forms/Form';

export default class Form extends FormComponent {

  static key = 'organization';

  renderBody() {
    return (
      <div>
        {this.renderMainFields()}
        <hr />
        {this.renderOrganizationFields()}
        <hr />
        {this.renderTransferFields()}
        <hr />
        {this.props.currentUser.role == 'super_admin' && (
          <div>
            {this.renderFtpFields()}
            <hr />
          </div>
        )}
        {this.renderDataFields()}
        <hr />
        {this.renderBexioFields()}
        <hr />
        {this.renderPriceFields()}
      </div>
    );
  }

  renderMainFields() {
    return (
      <div>
        {this.form.fields.checkbox('organization.distributor', 'Distributor')}

        {this.form.fields.checkbox(
          'organization.show_logo_on_login_page',
          'Show logo on login page',
        )}

        {this.form.fields.input('organization.name', 'Name')}
        {this.form.fields.file('organization.logo', 'Logo')}

        {this.form.fields.input(
          'organization.notification_email',
          'Notification Email',
        )}

        {this.form.fields.input('organization.kdm_email', 'Kdm Email', { disabled: this.props.currentUser.role != 'super_admin' })}

        {this.form.fields.input('organization.delete_authority_email', 'Delete Authority Email')}

        {this.form.fields.input('organization.email', 'Email')}

        {this.form.fields.dropdown(
          'organization.feature_organizations_ids',
          'Invoice Group Feature',
          {valuesObjects: this.state.organizations}
        )}

        {this.form.fields.dropdown(
          'organization.kdm_organizations_ids',
          'Invoice Group KDM',
          {valuesObjects: this.state.organizations}
        )}

        {this.form.fields.select(
          'organization.billing_mode_cd',
          'Billing Mode',
          {values: this.state.billing_modes}
        )}

        {this.state.organization.billing_mode_cd == 1 && (
          this.form.fields.input(
            'organization.trailer_invoice_minimal_transfers',
            'Trailer Invoice Minimal Transfers',
          )
        )}

        {this.form.fields.select(
          'organization.kdm_billing_mode',
          'Kdm Billing Mode',
          {valuesArray: this.state.kdm_billing_modes}
        )}

        {this.form.fields.checkbox(
          'organization.feature_group_discounts',
          'Group feature rebates',
        )}

        {this.form.fields.checkbox(
          'organization.kdm_group_discounts',
          'Group KDM rebates',
        )}

        {this.form.fields.checkbox(
          'organization.second_run_pricing',
          'Second run pricing',
        )}

        {this.form.fields.select(
          'organization.invoice_interval',
          'Feature & KDM Invoice Interval',
          {
            values: [
              [1, '1 month'],
              [2, '2 months'],
              [3, '3 months'],
              [4, '4 months'],
              [6, '6 months'],
              [12, '12 months'],
            ],
          },
        )}

        {this.form.fields.select(
          'organization.ungroup_feature_invoices',
          'Feature invoice mode',
          {
            values: [
              [false, 'per site / day'],
              [true, 'per site / forever'],
            ],
            blank: false,
          },
        )}

        {this.form.fields.checkboxes(
          'organization.target_ids',
          'Available Targets',
          {valuesObjects: this.state.targets},
        )}
      </div>
    );
  }

  renderOrganizationFields() {
    return (
      <div>
        {this.form.fields.select(
          'organization.region_code',
          'Region',
          {valuesArray: this.state.region_codes},
        )}

        {this.form.fields.select(
          'organization.city_id',
          'City',
          {valuesObjects: this.state.cities},
        )}

        {this.form.fields.input('organization.address', 'Address')}

        <Row title="Postal Code / Place">
          <div className="row">
            <div className="col-sm-4 organization-edit__field-postal-code">
              {this.form.fields.input('organization.postal_code')}
            </div>
            <div className="col-sm-8 organization-edit__field-place">
              {this.form.fields.input('organization.place')}
            </div>
          </div>
        </Row>

        {this.form.fields.input('organization.phone', 'Phone')}

        <Row title="Coordinates">
          <div className="row">
            <div className="col-sm-6 organization-edit__field-lat">
              {this.form.fields.input('organization.lat')}
            </div>
            <div className="col-sm-6 organization-edit__field-lng">
              {this.form.fields.input('organization.lng')}
            </div>
          </div>
        </Row>

        {this.form.fields.input('organization.eden_site_id', 'OFE Site ID')}

        {this.form.fields.input(
          'organization.deluxe_site_id',
          'Deluxe Site ID',
        )}

        {this.props.currentUser.role == 'super_admin' && (
          this.form.fields.input(
            'organization.gofilex_company_id',
            'Company ID',
          )
        )}
      </div>
    );
  }

  renderTransferFields() {
    return (
      <div>
        {this.props.currentUser.role == 'super_admin' && (
          <div>
            {this.form.fields.select(
              'organization.transfer_mode_cd',
              'Transfer Mode',
              {values: this.state.transfer_modes, blank: false, default: 3 },
            )}
            {this.form.fields.select(
              'organization.transfer_mode_ftr',
              'Feature Transfer Mode',
              { valuesArray: this.state.transfer_mode_ftrs, blank: false, default: 'disk' },
            )}
          </div>
        )}

        {this.form.fields.dropdown(
          'organization.accelerator_organizations_ids',
          'Accelerator Neighbours',
          {valuesObjects: this.state.organizations},
        )}

        {this.form.fields.dropdown(
          'organization.disk_transfer_distributor_ids',
          'Disk Transfers Distributors',
          {valuesObjects: this.state.distributors},
        )}
      </div>
    );
  }

  renderFtpFields() {
    return (
      <div>
        {this.form.fields.input(
          'organization.ftp_pull_credentials.0.login',
          'Ftp Pull Name',
        )}

        {this.form.fields.input(
          'organization.ftp_pull_credentials.0.password',
          'Ftp Pull Password',
        )}
      </div>
    );
  }

  renderDataFields() {
    return (
      <div>
        {this.form.fields.checkbox(
          'organization.no_delivery_mail',
          'No Delivery Mail',
        )}

        {this.form.fields.checkbox(
          'organization.no_trailer_invoices',
          'No Trailer Invoices',
        )}

        {this.form.fields.checkbox(
          'organization.no_feature_invoices',
          'No Feature Invoices',
        )}

        {this.form.fields.checkbox(
          'organization.no_kdm_invoices',
          'No Kdm Invoices',
        )}

        {this.form.fields.select(
          'organization.kind_cd',
          'Kind',
          {values: this.state.kinds}
        )}

        {this.form.fields.input(
          'organization.films_started_2014',
          'films started 2014',
        )}

        {this.form.fields.input(
          'organization.films_started_2015',
          'films started 2015',
        )}

        {this.form.fields.input(
          'organization.films_started_2016',
          'films started 2016',
        )}

        {this.form.fields.input(
          'organization.films_started_2017',
          'films started 2017',
        )}

        {this.form.fields.input(
          'organization.films_started_2018',
          'films started 2018',
        )}

        {this.form.fields.input(
          'organization.films_started_2019',
          'films started 2019',
        )}

        {this.form.fields.input(
          'organization.films_started_2020',
          'films started 2020',
        )}

        {this.form.fields.select(
          'organization.connection_cd',
          'Connection',
          {values: this.state.connections}
        )}

        {this.form.fields.select(
          'organization.connection_type_cd',
          'Connection Type',
          {values: this.state.connection_types}
        )}

        {this.form.fields.input(
          'organization.connection_vendor',
          'Connection Vendor',
        )}

        {this.form.fields.input(
          'organization.connection_real_bandwidth',
          'Connection Real Bandwidth',
        )}

        {this.form.fields.select(
          'organization.connection_owner_cd',
          'Connection Owner',
          {values: this.state.connection_owners},
        )}

        {this.form.fields.input(
          'organization.connection_cost_per_month',
          'Connection Cost Per Month',
        )}

        {this.form.fields.input(
          'organization.connection_contract_time',
          'Connection Contract Time',
        )}
      </div>
    );
  }

  renderBexioFields() {
    return (
      <div>
        <h2>Bexio</h2>

        {this.form.fields.select(
          'organization.billing_account_id',
          'Billing Via',
          {valuesObjects: this.state.billing_accounts}
        )}

        {this.form.fields.input(
          'organization.bexio_contact_id',
          'contact_id',
        )}

        {this.form.fields.input(
          'organization.bexio_currency_id',
          'currency_id',
        )}

        {this.form.fields.input(
          'organization.bexio_bank_account_id',
          'bank_account_id',
        )}

        {this.form.fields.input(
          'organization.bexio_payment_type_id',
          'payment_type_id',
        )}

        {this.form.fields.input(
          'organization.bexio_language_id',
          'language_id',
        )}

        {this.form.fields.input(
          'organization.bexio_tax_id',
          'tax_id',
        )}

        {this.form.fields.input(
          'organization.bexio_payment_type',
          'payment_type',
        )}

        {this.form.fields.input(
          'organization.bexio_name',
          'name',
        )}
      </div>
    );
  }

  renderPriceFields() {
    return (
      <div>
        <h2>Prices</h2>

        {this.form.fields.input(
          'organization.price_dkdm',
          'DKDM',
          { comment: 'Art-100' }
        )}

        {this.form.fields.input(
          'organization.price_delivery_feature_flat',
          'Delivery Feature Flat ',
          { comment: 'Art-186' }
        )}

        {this.form.fields.input(
          'organization.price_key',
          'Key',
          { comment: 'Key' }
        )}

        {this.form.fields.input(
          'organization.price_center_key',
          'Center-Key',
          { comment: 'Center-key' }
        )}

        {this.form.fields.input(
          'organization.price_dcp_trailerdistribution_flat',
          'DCP Trailerdistribution flat',
          { comment: 'Art-24' }
        )}

        {this.form.fields.input(
          'organization.price_rabatt_key_gruppe',
          'Rabatt Key Gruppe',
          { comment: 'Art-190' }
        )}

        {this.form.fields.input(
          'organization.price_rabatt_center_key_gruppe',
          'Rabatt Center Key Gruppe',
          { comment: 'Art-191' }
        )}

        {this.form.fields.input(
          'organization.price_rabatt_feature_delivery_gruppe',
          'Rabatt Feature Delivery Gruppe',
          { comment: 'Art-192' }
        )}

        {this.form.fields.input(
          'organization.price_dcp_trailerdistribution_grundpreis',
          'DCP Trailerdistribution Grundpreis',
          { comment: 'Art-13' }
        )}

        {this.form.fields.input(
          'organization.price_dcp_trailerdistribution_transfer',
          'DCP Trailerdistribution Transfer',
          { comment: 'Art-14' }
        )}

        {this.form.fields.input(
          'organization.price_foyervideo_konvertierung_und_distribution',
          'Foyervideo Konvertierung und Distribution',
          { comment: 'Art-15' }
        )}

        {this.form.fields.input(
          'organization.price_single_key',
          'Single-Key',
          { comment: 'Art-196' }
        )}

        {this.form.fields.input(
          'organization.price_delivery_feature_flat_2nd_run',
          'Delivery Feature Flat 2nd run ',
          { comment: 'Art-209' }
        )}


      </div>
    );
  }

}
