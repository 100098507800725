import React from 'react';
import moment from 'moment/moment';

import Form from 'services/Form';
import DateTimePicker from 'services/DateTimePicker';
import I18n from 'services/I18n';

const t = I18n.namespace('deliver');

export default class DeliverForm extends React.Component {

  constructor(props) {
    super(props)

    this.form = new Form(this, {
      onSetState: (state) => {
        this.props.set(state.delivery);
        this.props.setErrors(state.errors);
      },
    });

    let defaultDistributorId = null;
    if(props.distributors[0]) {
      defaultDistributorId = props.distributors[0].id;
    }

    this.state = {
      delivery: {
        notification_distributor_id: props.film.notification_distributor_id,
        kdm_invoice_distributor_id: defaultDistributorId,
        distributor_id: defaultDistributorId,
      },
      errors: props.errors,
    };

    if(!this.state.delivery.notification_distributor_id) {
      this.state.delivery.notification_distributor_id = defaultDistributorId;
    }
  }

  componentWillReceiveProps(props) {
    this.setState({errors: props.errors});
  }

  render() {
    return (
      <div>
        {this.props.kdms && this.form.fields.input(
          'delivery.start_at',
          t('kdm_valid_from'),
          {
            input: {
              className: 'form-control',
              value: undefined,
              defaultValue: I18n.datetime(
                this.state.delivery.start_at,
              ),
              onFocus: (event) => {
                if(event.target.value === '') {
                  event.target.value = moment().format('DD.MM.YYYY 00:00');
                }
              },
              ref: DateTimePicker.binder(
                (value) => {
                  this.form.set('delivery.start_at', value);
                },
                {format: 'DD.MM.YYYY HH:mm'},
              ),
              style: {
                width: '175px',
                minWidth: '150px',
              },
            },
          },
        )}

        {this.props.kdms && this.form.fields.input(
          'delivery.end_at',
          t('kdm_valid_to'),
          {
            input: {
              className: 'form-control',
              value: undefined,
              defaultValue: I18n.datetime(
                this.state.delivery.end_at,
              ),
              onFocus: (event) => {
                if(event.target.value === '') {
                  event.target.value = moment().format('DD.MM.YYYY 23:59');
                }
              },
              ref: DateTimePicker.binder(
                (value) => {
                  this.form.set('delivery.end_at', value);
                },
                {format: 'DD.MM.YYYY HH:mm'},
              ),
              style: {
                width: '175px',
                minWidth: '150px',
              },
            },
          },
        )}

        {this.props.playlists && this.form.fields.input(
          'delivery.due_date',
          t('dcp_due_date'),
          {
            input: {
              className: 'form-control',
              value: undefined,
              defaultValue: I18n.datetime(
                this.state.delivery.due_date,
              ),
              onFocus: (event) => {
                if(event.target.value === '') {
                  event.target.value =
                    this
                      .getEarliestPossibleDeliveryDate()
                      .format('DD.MM.YYYY HH:mm');
                }
              },
              ref: DateTimePicker.binder(
                this.form.setter('delivery.due_date'),
                {format: 'DD.MM.YYYY HH:mm'},
              ),
              style: {
                width: '175px',
                minWidth: '150px',
              },
            },
          },
        )}

        {this.form.fields.textarea(
          'delivery.message',
          t('message_to_recipients'),
          {
            element: {
              rows: 10,
              cols: 60,
              className: 'form-control',
            },
          },
        )}

        {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && (
          <div>
            {this.props.kdms && this.form.fields.select(
              'delivery.kdm_invoice_distributor_id',
              'Kdm invoice to',
              {
                blank: 'No KDM invoice',
                valuesObjects: this.props.distributors,
              },
            )}

            {this.form.fields.select(
              'delivery.distributor_id',
              'Transfer invoice to',
              {
                blank: 'No invoice',
                valuesObjects: this.props.distributors,
              },
            )}

            {this.form.fields.select(
              'delivery.notification_distributor_id',
              'Notification by',
              {
                blank: 'No notification',
                valuesObjects: this.props.distributors,
              },
            )}
          </div>
        )}

        {this.form.fields.textarea(
          'delivery.diagonal_message',
          t('message_to_diagonal'),
          {
            element: {
              rows: 10,
              className: 'form-control',
            },
          },
        )}
      </div>
    );
  }

  getEarliestPossibleDeliveryDate() {
    var now = moment();
    if(now.day() == 5 && now.hour() >= 16 || now.day() > 5) {
      return now.day(9).hours(10).minutes(0).seconds(0);
    }

    var nextDayShift = 1;
    if(now.hour() >= 16) {
      nextDayShift = 2;
    }

    var result =
      now
        .add(nextDayShift, 'days')
        .hours(10)
        .minutes(0)
        .seconds(0);

    if(result.day() > 3) {
      result.day(10);
    } else {
      result.day(3);
    }

    return result;
  }
}
