import React from 'react';

import App from 'components/App';

import Form from 'services/Form';
import I18n from 'services/I18n';

const compact = require('lodash/compact');

export default class Notification extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      film: {},
      notification: {},
    };

    this.form = new Form(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this
      .form
      .api
      .receive('/films/' + this.props.filmId + '/notification')
      .then(() => {
        window.setTimeout(() => {
          let notification = {...this.state.notification};

          this.state.film.regions.forEach((region) => {
            if(!notification[region.locale]) {
              notification[region.locale] = {}
            }

            let title = region.title || this.state.film.regions.find(r => r.locale == 'en').title;

            notification[region.locale].subject =
              I18n.t('notification.subject', {
                locale: region.locale,
                title: title,
                user: this.state.film.organization.name,
              });

            let titleText = I18n.t(
              `notification.${notification[region.locale].count == 1 ?
                'trailer' : 'trailers'}`,
              {
                locale: region.locale,
                organization: this.state.film.organization.name,
              }
            );


            let titles = notification[region.locale].titles || {};
            let titlesText =
              Object
                .keys(titles)
                .map((key) => {
                  let result =
                    key +
                      '\n' +
                      titles[key]
                        .map((title) => {
                          return title.title;
                        })
                        .join('\n');

                  return result;
                })
                .join('\n\n');

            notification[region.locale].content =
              I18n.t('notification.content', {
                locale: region.locale,
                text: compact([
                  titleText,
                  titlesText && titlesText + '\n',
                  'http://' + window.location.host + '/films/' +
                    this.state.film.id + '\n',
                  compact(
                    [
                      region.title,
                      this
                        .state
                        .film
                        .countries
                        .map((country) => {
                          return country[region.locale];
                        })
                        .join(' / '),
                      this.state.film.year,
                    ],
                  ).join(', '),
                  region.description && '\n' + region.description,
                ]).join('\n'),
                starts: I18n.datetime(region.release_at) || 'TBA',
              });
          });

          this.setState({notification: notification});
        });
      });
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();

    this
      .form
      .api
      .post('/films/' + this.props.filmId + '/notification', {
        notification: this.state.notification,
      })
      .then(() => {
        App.history.push('/films/' + this.props.filmId + '/edit');
      });
  }

  render() {
    let region = (this.state.film.regions || []).find((region) => {
      return region.title;
    });

    return (
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 section-title">
              <h1>
                Create notification for "{region && region.title || '...'}"
              </h1>
            </div>
          </div>
        </div>

        <form onSubmit={this.submit}>
          {this.renderForm('German', 'de')}
          {this.renderForm('French', 'fr')}
          {this.renderForm('Italian', 'it')}

          {!this.state.film.activated && (
            <div className="container">
              <div className="form-group row">
                <div className="col-xs-12" style={{color: 'red'}}>
                  Film should be active to send notifications
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <div className="form-group row">
              <div className="col-xs-12">
                <button
                  className="mail-send btn btn-primary"
                  type="submit"
                  disabled={!this.state.film.activated}
                >
                  Send
                </button>

                &nbsp;

                <a
                  href={'/films/' + this.props.filmId + '/edit'}
                  className="btn btn-danger"
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </form>
      </section>
    );
  }

  renderForm(title, locale) {
    let region = (this.state.film.regions || []).find((region) => {
      return region.locale == locale;
    });

    if(!region) {
      return null;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 section-subtitle">
            <h2>{title}</h2>
          </div>
        </div>

        <div className="jumbotron">
          {this.form.fields.checkbox(
            'notification.' + locale + '.enabled',
            'Notification',
          )}

          {this.form.fields.input(
            'notification.' + locale + '.subject',
            'Title',
          )}

          {this.form.fields.textarea(
            'notification.' + locale + '.content',
            'Text',
            {
              element: {
                rows: 8,
              },
            },
          )}

          {
            this.state.notification[locale].last_notification &&
              this.form.fields.value(
                'notification.' + locale + '.last_sent',
                'Last Sent',
                {
                  value:
                    I18n.datetime(
                      this
                        .state
                        .notification[locale]
                        .last_notification
                        .created_at,
                    ),
                },
              )
          }
        </div>
      </div>
    );
  }

}
