import React from 'react';

import I18n from 'services/I18n';
import Errors from 'services/Errors';
import Query from 'services/Query';

import EditTransfer from '../../Transfers/Edit';
import Utilities from '../Utilities';

export default class Transfers extends React.Component {

  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.delete = this.delete.bind(this);
    this.showEditDialog = this.showEditDialog.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentWillReceiveProps(props) {
    if(!props.transfers) {
      return ;
    }

    let transferId = Query.get('editTransfer');
    let transfer = props.transfers.find((localTransfer) => {
      return localTransfer.id.toString() == transferId;
    });

    if(!transfer) {
      return ;
    }

    this.setState({transfer: transfer});
  }

  delete(event) {
    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    if(!confirm('Are you sure you want to delete transfer?')) {
      return ;
    }

    let transferId = target.dataset.transfer;
    this
      .props
      .api
      .delete('/transfers/' + transferId)
      .then(() => {
        let newTransfers = this.props.transfers.filter((transfer) => {
          return transfer.id.toString() != transferId;
        });

        this.props.setTransfers(this.props.playlistId, newTransfers);
      })
      .catch(Errors.alertHandler('failed to delete transfer'));

  }

  load() {
    this.props.loadTransfers(this.props.playlistId);
  }

  setTransfer(transfer) {
    let transfers = this.props.transfers.map((localTransfer) => {
      if(localTransfer.id == transfer.id) {
        return transfer;
      }

      return localTransfer;
    });

    this.props.setTransfers(this.props.playlistId, transfers);
  }

  showEditDialog(event) {
    event.preventDefault();
    event.stopPropagation();

    if(!this.props.user_can_manage) {
      return ;
    }

    Query.set('editTransfer', event.target.dataset.transfer);
  }

  render() {
    if(!this.props.transfers) {
      return null;
    }

    let transferId = Query.get('editTransfer');

    return (
      <tbody
        className={
          'table-body--asset-details ' +
            (this.props.playlists.length > 1 && 'table-body--assetgroup')
        }
      >
        {transferId && (
          <EditTransfer
            api={this.props.api}
            transferId={transferId}
            transfer={this.props.transfers.find((transfer) => {
              return transfer.id.toString() == transferId;
            })}
            reload={this.load}
            onChange={this.setTransfer.bind(this)}
          />
        )}

        {this.props.transfers && this.props.transfers.map((transfer) => {
          return (
            <tr className="table-row--asset-detail" key={transfer.id}>
              <td></td>
              <td
                className={
                  'table-cell--icon transfer-icon history ' +
                    (!transfer.tracking_url && 'non_trackable') + ' ' +
                    (
                      (transfer.type || '')
                        .split(/(?=[A-Z])/)
                        .join('_')
                        .toLowerCase()
                    )
                }
              >
                {Utilities.getTransferIcon(transfer.status_cd, transfer.movietransit_status, transfer.type)}
              </td>

              <td className="table-cell--asset-detail" colSpan="11">
                <span className="asset-detail-date">
                  <a
                    href={this.props.user_can_manage && '#'}
                    className="grey"
                    data-transfer={transfer.id}
                    onClick={this.showEditDialog}
                  >
                    {I18n.datetime(
                      transfer.prepared_date || transfer.created_at
                    )}
                  </a>
                </span>

                <span className="asset-detail-name">
                  {transfer.organization_name ||
                    (transfer.organization && transfer.organization.name)}

                  {' '}

                  {transfer.user_email ||
                    (transfer.user && transfer.user.email)}

                  {this.props.user_can_manage && this.props.currentUser.role == 'super_admin' && (
                    <a
                      href="#"
                      data-transfer={transfer.id}
                      onClick={this.delete}
                      className="soft-gray"
                    >
                      <i className="icon fa fa-times fa-fw"></i>
                    </a>
                  )}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

}
