import React from 'react';

import FormApi from './FormApi';
import FormFields from './FormFields';

export default class Form {

  static value(value) {
    if(value === null) {
      return '';
    }

    if(value === undefined) {
      return '';
    }

    return value;
  }

  static text(text) {
    if(!text) {
      return null;
    }

    return text.split('\n').map((part, index) => {
      return (
        <span key={index}>{part}<br /></span>
      );
    });
  }

  constructor(component, options = {}) {
    this.component = component;
    this.api = new FormApi(this.component);
    this.fields = new FormFields(this);
    this.consts = {};
    this.onSetState = options.onSetState || (() => {});
  }

  get(key) {
    if (!key) return;

    if(!(key instanceof Array)) {
      key = key.split('.');
    }

    let result = this.component.state;
    key.forEach((part) => {
      result = (result || {})[part];
    });

    return result;
  }

  extend(key, value) {
    let keys = this.keyToArray(key);
    let result = {
      ...this.component.state,
      errors: {
        ...(this.component.state || {}),
        fatal: null,
      },
    };

    Object.keys(value).forEach((valueKey) => {
      result = this.put(result, keys.concat([valueKey]), value[valueKey]);

      result = this.put(
        result,
        ['errors'].concat(keys).concat([valueKey]),
        null,
      );
    });

    this.component.setState(result);
    this.onSetState(result);
  }

  set(key, value) {
    let keys = this.keyToArray(key);
    let newState = {...this.component.state};

    let isValueModified = false;
    if(this.get(keys) !== value) {
      newState = this.put(newState, keys, value);
      isValueModified = true;
    }

    let errorKeys = keys;
    if(errorKeys.length > 1) {
      errorKeys = errorKeys.slice(1);
    }

    let isErrorsModififed = false;
    if(this.get(['errors'].concat(errorKeys))) {
      newState = this.put(newState, ['errors'].concat(errorKeys), null);
      isErrorsModififed = true;
    }

    let isFatalModififed = false;
    if(this.get(['errors', 'fatal'])) {
      newState = this.put(newState, ['errors', 'fatal'], null);
      isFatalModififed = true;
    }

    if(!isValueModified && !isErrorsModififed && !isFatalModififed) {
      return ;
    }

    this.component.setState(newState);
    this.onSetState(newState);
  }

  append(key, value) {
    this.set(key, [...this.get(key), value]);
  }

  remove(key) {
    let keys = this.keyToArray(key);
    let part = keys.slice(0, keys.length - 1);
    let value = this.get(part);
    let lastKey = keys[keys.length - 1];

    this.set(part, value.filter((_, currentKey) => {
      return currentKey.toString() != lastKey.toString();
    }))
  }

  toggle(key, value, set = null) {
    return this.toggleArray(key, [value], set);
  }

  toggleArray(key, value, set = null) {
    let uniqueValue = [...new Set(value)];

    let found = false;
    let result = [...this.get(key)].filter((current) => {
      if(uniqueValue.includes(current)) {
        found = true;
        return false;
      }

      return true;
    });

    if(set === true || (set === null && !found)) {
      uniqueValue.forEach((entry) => {
        result.push(entry);
      });
    }

    this.set(key, result);
  }

  put(object, key, value) {
    let keys = this.keyToArray(key);
    let result = null;
    if(object instanceof Array) {
      result = [...object];
    } else {
      result = {...object};
    }

    let current = result;

    keys.slice(0, keys.length - 1).forEach((part) => {
      if(current[part] instanceof Array) {
        current[part] = [...(current[part] || {})];
      } else {
        current[part] = {...(current[part] || {})};
      }

      current = current[part];
    });

    current[keys[keys.length - 1]] = value;
    return result
  }

  callback(key, callback) {
    return this.const(key + '.callback', callback);
  }

  const(key, value) {
    if(!this.consts[key]) {
      this.consts[key] = value;
    }

    return this.consts[key];
  }

  setter(key, transform = null) {
    let keys = this.keyToArray(key);

    let field = keys.join('.');
    if(!this.consts[field]) {
      let setter = (event, originalEvent) => {
        let value = event;

        if(originalEvent) {
          event = originalEvent;
        } else if(value && value.target) {
          if(value.target.type == 'checkbox' || value.target.type == 'radio') {
            value = value.target.checked;
          } else {
            value = value.target.value;
          }
        }

        if(transform) {
          value = transform(value, event);
        }

        this.set(key, value);
      }

      this.consts[field] = setter;
    }

    return this.consts[field];
  }

  keyToArray(key) {
    if(!(key instanceof Array)) {
      key = key.split('.');
    }

    return key.map((part) => {
      if(part.match && part.match(/^\d+$/)) {
        return parseInt(part);
      }

      return part;
    });
  }

}
