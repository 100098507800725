import React from 'react';
import Modal from 'react-modal';

import Errors from 'components/Forms/Errors';

import ErrorsService from 'services/Errors';
import FormService from 'services/Form';
import I18n from 'services/I18n';
import ModalService from 'services/Modal';
import Query from 'services/Query';

export default class Order extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      display: true,
      cinemasIds: this.getAllCinemasIds(),
      errors: {},
    };

    this.form = new FormService(this);

    this.close = this.close.bind(this);
    this.deliver = this.deliver.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.download = this.download.bind(this);
  }

  getAllCinemasIds() {
    return this.props.cinemas.map((cinema) => {
      return cinema.id;
    });
  }

  close() {
    this.setState({display: false});
    Query.set('order', null);
  }

  deliver(event) {
    event.preventDefault();

    let url = '/transfers/create_trailer_transfers';
    let data = {
      cinemas_ids: this.state.cinemasIds,
      playlist_id: this.props.playlist.id,
    };

    return this.form.api.post(url, data).then(() => {
      this.props.loadTransfers(this.props.playlist.id);
      this.close();
    });
  }

  toggle(event) {
    let cinemaId = parseInt(event.target.dataset.cinema);
    let cinemasIds = [...this.state.cinemasIds];
    let index = cinemasIds.indexOf(cinemaId);
    if(index !== -1) {
      cinemasIds.splice(index, 1);
    }

    if(event.target.checked) {
      cinemasIds.push(cinemaId);
    }

    this.setState({cinemasIds: cinemasIds});
  }

  toggleAll(event) {
    if(!event.target.checked) {
      this.setState({cinemasIds: []});
      return ;
    }

    this.setState({cinemasIds: this.getAllCinemasIds()});
  }

  download(event) {
    event.preventDefault();
    event.stopPropagation();

    this
      .props
      .api
      .get('/playlists/' + event.target.dataset.playlist + '/download')
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch(ErrorsService.alertHandler('download failed'));
  }

  renderZipDownload() {
    if (this.props.playlist.sources.length > 0 && !this.props.playlist.sources.includes('filmservice')) {
      return
    }

    return(
      <div style={{ marginBottom: '12px' }}>
        <a
          href="#"
          title={I18n.t('films.download')}
          data-playlist={this.props.playlist.id}
          onClick={this.download}
          disabled={this.state.loading}
        >
          {I18n.t('film.download')}
        </a>
      </div>
    )
  }

  render() {
    return (
      <Modal
        appElement={document.getElementById('app')}
        isOpen={this.state.display}
        style={ModalService.STYLE}
        onRequestClose={this.close}
        contentLabel="Deliver DCP"
      >
        <button
          className="close"
          onClick={this.close}
        >
          <span aria-hidden="true">×</span>
        </button>

        {I18n.t('film.download_to')}

        <form onSubmit={this.deliver}>
          <div style={{margin: '12px 0px'}}>
            <div className="transfer" style={{margin: '12px 0px'}}>
              <div className="transfer_checkbox">
                <label className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={this.toggleAll}
                    checked={
                      this.props.cinemas.every((cinema) => {
                        return this.state.cinemasIds.includes(cinema.id);
                      })
                    }
                  />

                  <span className="custom-control-indicator">
                    <i className="icon fa fa-check"></i>
                  </span>

                  <span className="custom-control-description">
                    <span className="transfer_name">
                      <span className="organization_name">
                        {I18n.t('film.all_theaters')}
                      </span>
                      <span className="transfer_status"></span>
                    </span>
                  </span>
                </label>
              </div>
            </div>

            {this.props.cinemas && this.props.cinemas.map((cinema) => {
              return (
                <div className="transfer" key={cinema.id}>
                  <div className="transfer_checkbox">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={this.state.cinemasIds.includes(cinema.id)}
                        data-cinema={cinema.id}
                        onChange={this.toggle}
                      />

                      <span className="custom-control-indicator">
                        <i className="icon fa fa-check"></i>
                      </span>

                      <span className="custom-control-description">
                        <span className="transfer_name">
                          <span className="organization_name">
                            {cinema.name}
                          </span>

                          <span className="transfer_status"></span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>

          <Errors errors={this.state.errors.fatal} />

          {this.renderZipDownload()}

          <button
            className="btn btn-success"
            type="submit"
            disabled={this.state.loading}
          >
            {I18n.t('film.transfer')}
          </button>

          {' '}

          <button
            className="btn btn-danger"
            onClick={this.close}
            type="button"
            disabled={this.state.loading}
          >
            {I18n.t('film.close')}
          </button>
        </form>
      </Modal>
    );
  }

}
